.location-img-container {
	position: relative;
	overflow: hidden;
	border-radius: 0.5rem; // Rounded corners

	&:hover img {
		transform: scale(1.05);
	}

	&:hover .location-img-overlay {
		opacity: 0.3;
	}

	img {
		transition: transform 0.5s ease-in-out;
	}

	.location-img-overlay {
		transition: opacity 0.5s ease-in-out;
	}
}
