.event-img-container {
	position: relative;
	overflow: hidden;
	border-radius: 0.5rem; // Rounded corners
	perspective: 1000px; // Add this line

	&:hover img {
		transform: scale(1.05);
	}

	// &:hover .event-img-overlay {
	// 	opacity: 0.3;
	// }

	img {
		transition: transform 0.5s ease-in-out;
	}

	.event-img-overlay {
		transition: opacity 0.5s ease-in-out;
	}
}
