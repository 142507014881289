@tailwind base;
@tailwind components;
@tailwind utilities;

/* Regular weight */
@font-face {
	font-family: 'Glypha';
	src: url('./assets/fonts/GlyphaLTPro55Rg.TTF') format('truetype');
	font-weight: 400; /* Normal weight */
}

/* Bold weight */
@font-face {
	font-family: 'Glypha';
	src: url('./assets/fonts/GlyphaLTPro65Bold.TTF') format('truetype');
	font-weight: 700; /* Bold weight */
}

body {
	font-family: 'Glypha', sans-serif;
}
